import { useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import Layout from "../../Components/Layout/Layout";
import classes from "./Home.module.css";
import { inputChangeHandler } from "../../HelperFunctions/inputChangeHandler";
import ConfirmationModal from "../../Components/Modals/ConfirmationModal/ConfirmationModal";
import DeleteConfirmModalBody from "../DeleteConfirmModalBody/DeleteConfirmModalBody";
import EnterOtpModalBody from "../EnterOtpModalBody/EnterOtpModalBody";
import { otpType } from "../../Utilities/types";
import DeletionCompleteModalBody from "../DeletionCompleteModalBody/DeletionCompleteModalBody";
import LegalNoticeModalBody from "../LegalNoticeModalBody/LegalNoticeModalBody";
import PrivacyPolicyModalBody from "../PrivacyPolicyModalBody/PrivacyPolicyModalBody";
import CookiePolicyContainer from "../CookiePolicyContainer/CookiePolicyContainer";

const Home = () => {
  // States
  const [credentials, setCredentials] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showEnterOtpModal, setShowEnterOtpModal] = useState(false);
  const [showDeletionComplete, setShowDeletionComplete] = useState(false);
  const [showLegalNoticeModal, setShowLegalNoticsModal] = useState(false);
  const [otp, setOtp] = useState<otpType>({ otp: "" });
  const [sendingOtpRequest, setSendingOtpRequest] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [countdownTimer, setCounddownTimer] = useState(0);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showCookieToast, setShowCookieToast] = useState(true);

  const manageOtp = () => {
    if (otp.otp) {
      setSendingOtpRequest(true);
      setTimeout(() => {
        if (otp.otp !== "123456") {
          setOtpError("Invalid OTP Code");
          setSendingOtpRequest(false);
        } else {
          setSendingOtpRequest(true);
          setOtpError("");

          setSendingOtpRequest(false);
          setShowEnterOtpModal(false);
          setShowDeletionComplete(true);
        }
      }, 3000);
    }
  };

  const openConfirmModal = () => {
    if (credentials.email) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShowLegalNoticsModal(false);
        setShowDeleteConfirmModal(true);
      }, 3000);
    }
  };

  // Effects
  useEffect(() => {
    // Local
    const privacyConsent = localStorage.getItem("fcmb-privacy-consent");
    const cookieConsent = localStorage.getItem("fcmb-cookie-consent");

    if (privacyConsent === "true") {
      setShowPrivacyModal(false);
    } else {
      setShowPrivacyModal(true);
    }

    if (cookieConsent === "true") {
      setShowCookieToast(false);
    } else {
      setShowCookieToast(true);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Layout header="Delete Profile">
      {showPrivacyModal && (
        <ConfirmationModal
          onClick={() => {
            setShowPrivacyModal(false);
          }}
          body={
            <PrivacyPolicyModalBody
              onClick={() => {
                setShowPrivacyModal(false);
              }}
            />
          }
          style={{ top: "-30vh" }}
        />
      )}

      {showLegalNoticeModal && (
        <ConfirmationModal
          onClick={() => {
            setShowLegalNoticsModal(false);
          }}
          body={
            <LegalNoticeModalBody
              cancel={() => {
                setShowLegalNoticsModal(false);
              }}
              confirm={() => {
                openConfirmModal();
              }}
              loading={loading}
            />
          }
        />
      )}

      {showDeleteConfirmModal && (
        <ConfirmationModal
          onClick={() => {
            setShowDeleteConfirmModal(false);
          }}
          body={
            <DeleteConfirmModalBody
              cancel={() => {
                setShowDeleteConfirmModal(false);
              }}
              confirm={() => {
                setShowDeleteConfirmModal(false);
                setShowEnterOtpModal(true);
              }}
            />
          }
        />
      )}

      {showEnterOtpModal && (
        <ConfirmationModal
          onClick={() => {
            setShowEnterOtpModal(false);
            setOtpError("");
          }}
          body={
            <EnterOtpModalBody
              otp={otp}
              setOtp={setOtp}
              onClick={manageOtp}
              sendingOtpRequest={sendingOtpRequest}
              otpError={otpError}
              countdownTimer={countdownTimer}
              setCounddownTimer={setCounddownTimer}
              email={credentials?.email}
            />
          }
        />
      )}

      {showDeletionComplete && (
        <ConfirmationModal
          onClick={() => {
            setShowDeletionComplete(false);
          }}
          body={
            <DeletionCompleteModalBody
              onClick={() => {
                setShowDeletionComplete(false);
              }}
            />
          }
        />
      )}
      <section className={classes.container}>
        <p>
          <span>NOTE:</span> This action will automatically delete your profile,
          files and personal data associated with our system.
        </p>

        <p>To Proceed:</p>
        <p>
          Please enter your registered FCMB email address to confirm your
          profile deletion.
        </p>

        <Input
          label="Email Address"
          placeholder="Enter your email"
          name="email"
          onChange={(e) => {
            inputChangeHandler(e, setCredentials);
          }}
        />

        <div>
          <Button type="secondary">Cancel</Button>
          <Button
            onClick={() => {
              if (credentials.email) {
                setShowLegalNoticsModal(true);
              }
            }}
            disabled={!credentials.email?.includes("@")}
          >
            Continue
          </Button>
        </div>

        {showCookieToast && (
          <CookiePolicyContainer
            onClick={() => {
              localStorage.setItem("fcmb-cookie-consent", "true");
              setShowCookieToast(false);
            }}
          />
        )}
      </section>
    </Layout>
  );
};

export default Home;
