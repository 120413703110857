// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CookiePolicyContainer_container__rewaz {
  box-shadow: var(--boxShadow);
}

.CookiePolicyContainer_container__rewaz > h4 {
  font-family: Gotham;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  text-align: left;
  color: var(--darkPurple);
  margin-bottom: 1rem;
}

.CookiePolicyContainer_container__rewaz > p {
  font-family: Gotham;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
  color: var(--darkPurple);
}

.CookiePolicyContainer_container__rewaz > p > a {
  color: var(--linkBlue);
  text-decoration: none;
}

.CookiePolicyContainer_buttonSection__vxsbM {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.CookiePolicyContainer_buttonSection__vxsbM > button {
  font-size: 1rem;
  max-width: 150px;
}

@media screen and (max-width: 767px) {
  .CookiePolicyContainer_buttonSection__vxsbM > button {
    font-size: 14px;
    max-width: 100px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .CookiePolicyContainer_buttonSection__vxsbM > button {
    font-size: 14px;
    max-width: 100px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Containers/CookiePolicyContainer/CookiePolicyContainer.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;AACF","sourcesContent":[".container {\n  box-shadow: var(--boxShadow);\n}\n\n.container > h4 {\n  font-family: Gotham;\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 150%;\n  text-align: left;\n  color: var(--darkPurple);\n  margin-bottom: 1rem;\n}\n\n.container > p {\n  font-family: Gotham;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 150%;\n  text-align: left;\n  color: var(--darkPurple);\n}\n\n.container > p > a {\n  color: var(--linkBlue);\n  text-decoration: none;\n}\n\n.buttonSection {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 1rem;\n  margin-top: 1rem;\n}\n\n.buttonSection > button {\n  font-size: 1rem;\n  max-width: 150px;\n}\n\n@media screen and (max-width: 767px) {\n  .buttonSection > button {\n    font-size: 14px;\n    max-width: 100px;\n  }\n}\n\n@media screen and (min-width: 767px) and (max-width: 1023px) {\n  .buttonSection > button {\n    font-size: 14px;\n    max-width: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CookiePolicyContainer_container__rewaz`,
	"buttonSection": `CookiePolicyContainer_buttonSection__vxsbM`
};
export default ___CSS_LOADER_EXPORT___;
