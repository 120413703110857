// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes Error_appear__dM6M6 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Error_container__vNPA8 {
  border: var(--border);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primaryText);
  font-family: Gotham;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid var(--red);
  background: var(--errorBgRed);
  margin: 1rem 0;
  animation: Error_appear__dM6M6 0.3s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Error/Error.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,4BAA4B;EAC5B,6BAA6B;EAC7B,cAAc;EACd,+CAAkC;AACpC","sourcesContent":["@keyframes appear {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n.container {\n  border: var(--border);\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  color: var(--primaryText);\n  font-family: Gotham;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  padding: 0.5rem;\n  border-radius: 5px;\n  border: 1px solid var(--red);\n  background: var(--errorBgRed);\n  margin: 1rem 0;\n  animation: appear 0.3s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Error_container__vNPA8`,
	"appear": `Error_appear__dM6M6`
};
export default ___CSS_LOADER_EXPORT___;
