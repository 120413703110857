import classes from "./DeletionCompleteModalBody.module.css";
import check from "../../Assets/Icons/check.svg";
import Button from "../../Components/Button/Button";

type DeletionCompleteModalBodyProps = {
  onClick: () => void;
};

const DeletionCompleteModalBody = ({
  onClick,
}: DeletionCompleteModalBodyProps) => {
  return (
    <div className={classes.container}>
      <div className={classes.checkIcon}>
        <img src={check} alt="Deleted" />
      </div>
      <h4>Deleted</h4>
      <p>You account has been disabled</p>
      <Button onClick={onClick}>Done</Button>
    </div>
  );
};

export default DeletionCompleteModalBody;
