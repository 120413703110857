// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_container__Vl9P4 {
  padding: 69px 75px;
  background-color: var(--primaryWhite);
}

.Layout_body__cGonb > h1 {
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: left;
  color: var(--headerBlack);
  margin-bottom: 4rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .Layout_container__Vl9P4 {
    padding: 1.5rem;
  }

  .Layout_body__cGonb > h1 {
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .Layout_container__Vl9P4 {
    padding: 1.5rem;
  }

  .Layout_body__cGonb > h1 {
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qCAAqC;AACvC;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;AACF","sourcesContent":[".container {\n  padding: 69px 75px;\n  background-color: var(--primaryWhite);\n}\n\n.body > h1 {\n  font-family: Inter;\n  font-size: 40px;\n  font-weight: 600;\n  line-height: 48.41px;\n  text-align: left;\n  color: var(--headerBlack);\n  margin-bottom: 4rem;\n}\n\n@media screen and (min-width: 767px) and (max-width: 1023px) {\n  .container {\n    padding: 1.5rem;\n  }\n\n  .body > h1 {\n    font-size: 20px;\n    line-height: 140%;\n    margin-bottom: 2rem;\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .container {\n    padding: 1.5rem;\n  }\n\n  .body > h1 {\n    font-size: 20px;\n    line-height: 140%;\n    margin-bottom: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Layout_container__Vl9P4`,
	"body": `Layout_body__cGonb`
};
export default ___CSS_LOADER_EXPORT___;
