// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes ConfirmationModal_appear__5ojGn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ConfirmationModal_backdrop__\\+HKSO {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 11;
  background: var(--modalBackground);
  cursor: auto;
}

.ConfirmationModal_modal__VudFp {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  overflow: hidden;
  background-color: white;
  margin: auto;
  animation: ConfirmationModal_appear__5ojGn 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  border-radius: 16px;
  height: fit-content;
  width: fit-content;
  min-width: 584px;
  min-height: 316px;
}

@supports (-webkit-touch-callout: none) {
  .ConfirmationModal_modal__VudFp {
    min-height: 416px;
  }
}

.ConfirmationModal_modal__VudFp > div {
  width: 100%;
  height: 100%;
  text-align: left;
}

.ConfirmationModal_footer__WYbVp {
  text-align: right;
  margin-right: 3rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .ConfirmationModal_modal__VudFp {
    width: fit-content;
    height: fit-content;
  }
}

@media (max-width: 767px) {
  .ConfirmationModal_modal__VudFp {
    height: fit-content;
    width: fit-content;
    min-width: 90vw;
    min-height: 40vh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Modals/ConfirmationModal/ConfirmationModal.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,WAAW;EACX,kCAAkC;EAClC,YAAY;AACd;;AAEA;EACE,eAAe;EACf,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,WAAW;EACX,gBAAgB;EAChB,uBAAuB;EACvB,YAAY;EACZ,2DAAkC;EAClC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;EAClB;AACF","sourcesContent":["@keyframes appear {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n.backdrop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: 11;\n  background: var(--modalBackground);\n  cursor: auto;\n}\n\n.modal {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 12;\n  overflow: hidden;\n  background-color: white;\n  margin: auto;\n  animation: appear 0.2s ease-in-out;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow-y: auto;\n  border-radius: 16px;\n  height: fit-content;\n  width: fit-content;\n  min-width: 584px;\n  min-height: 316px;\n}\n\n@supports (-webkit-touch-callout: none) {\n  .modal {\n    min-height: 416px;\n  }\n}\n\n.modal > div {\n  width: 100%;\n  height: 100%;\n  text-align: left;\n}\n\n.footer {\n  text-align: right;\n  margin-right: 3rem;\n  margin-bottom: 1rem;\n}\n\n@media (min-width: 768px) and (max-width: 1023px) {\n  .modal {\n    width: fit-content;\n    height: fit-content;\n  }\n}\n\n@media (max-width: 767px) {\n  .modal {\n    height: fit-content;\n    width: fit-content;\n    min-width: 90vw;\n    min-height: 40vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `ConfirmationModal_backdrop__+HKSO`,
	"modal": `ConfirmationModal_modal__VudFp`,
	"appear": `ConfirmationModal_appear__5ojGn`,
	"footer": `ConfirmationModal_footer__WYbVp`
};
export default ___CSS_LOADER_EXPORT___;
