import Button from "../../Components/Button/Button";
import classes from "./CookiePolicyContainer.module.css";

type CookiePolicyContainerTypes = {
  onClick: () => void;
};

const CookiePolicyContainer = ({ onClick }: CookiePolicyContainerTypes) => {
  return (
    <section className={classes.container}>
      <h4>We Use Cookies!</h4>

      <p>
        FCMB website uses cookies to ensure necessary functionality, improve
        your experience and analyze our traffic. By continuing into the website,
        you have given consent to our use of cookies. For more information read
        our{" "}
        <a
          href="https://www.fcmb.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </p>

      <div className={classes.buttonSection}>
        <Button onClick={onClick}>Accept all</Button>
        <Button type="secondary" onClick={onClick}>
          Reject all
        </Button>
      </div>
    </section>
  );
};

export default CookiePolicyContainer;
