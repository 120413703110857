import classes from "./DeleteConfirmModalBody.module.css";
import binIcon from "../../Assets/Icons/binIcon.png";
import Button from "../../Components/Button/Button";

type DeleteConfirmModalBodyProps = {
  cancel: () => void;
  confirm: () => void;
};

const DeleteConfirmModalBody = ({
  cancel,
  confirm,
}: DeleteConfirmModalBodyProps) => {
  return (
    <div className={classes.container}>
      <div className={classes.iconSection}>
        <img src={binIcon} alt="Delete" />
      </div>
      <p>Are you sure you want to delete your profile?</p>

      <div className={classes.buttonSection}>
        <Button type="secondary" onClick={cancel}>
          No, Cancel
        </Button>
        <Button onClick={confirm}>Yes, Continue</Button>
      </div>
    </div>
  );
};

export default DeleteConfirmModalBody;
