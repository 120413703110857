import Button from "../../Components/Button/Button";
import classes from "./PrivacyPolicyModalBody.module.css";

type PrivacyPolicyModalBodyTypes = {
  onClick: () => void;
};

const PrivacyPolicyModalBody = ({ onClick }: PrivacyPolicyModalBodyTypes) => {
  return (
    <div className={classes.container}>
      <h1>Protecting Your Privacy Is Important To Us</h1>

      <p>
        This Privacy Notice applies to our website -{" "}
        <a href="https://fcmb.com" target="_blank" rel="noreferrer">
          www.fcmb.com
        </a>{" "}
        and its associated subdomains (collectively, our “Service”). By
        accessing or using our Service, you consent and signify that you have
        read, understood, and agreed to our data collection practices described
        in our{" "}
        <a
          href="https://www.fcmb.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy.
        </a>
      </p>

      <p>
        By clicking "OK" you agree FCMB can collect data to be used for the
        operation of the website, maintain quality of service and provide
        general statistics regarding the website.
      </p>

      <div className={classes.consentSection}>
        <div>
          <input
            type="checkbox"
            id="dontShow"
            onChange={(e) => {
              console.log(e.target.checked);
              localStorage.setItem(
                "fcmb-privacy-consent",
                String(e.target.checked)
              );
            }}
          />
          <label htmlFor="dontShow">Don't show again</label>
        </div>

        <Button onClick={onClick}>OK</Button>
      </div>
    </div>
  );
};

export default PrivacyPolicyModalBody;
