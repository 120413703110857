import classes from "./Header.module.css";
import fcmbLogo from "../../Assets/Images/fcmbLogo.png";

const Header = () => {
  return (
    <section className={classes.container}>
      <img src={fcmbLogo} alt="FCMB Logo" />
    </section>
  );
};

export default Header;
